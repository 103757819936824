import React from "react"

export const PrivacyPolicyData = () => {
  const styles = `
  .lst-kix_9ip76qflzlq2-8 > li:before {
    content: "\x25a0   ";
  }
  ul.lst-kix_q72dr9flkute-0 {
    list-style-type: none;
  }
  ol.lst-kix_v5wwndz3vkk4-8.start {
    counter-reset: lst-ctn-kix_v5wwndz3vkk4-8 0;
  }
  .lst-kix_ayreg0ssts0u-8 > li {
    counter-increment: lst-ctn-kix_ayreg0ssts0u-8;
  }
  .lst-kix_9ip76qflzlq2-4 > li:before {
    content: "\x25cb   ";
  }
  ul.lst-kix_9ip76qflzlq2-8 {
    list-style-type: none;
  }
  ul.lst-kix_9ip76qflzlq2-7 {
    list-style-type: none;
  }
  ul.lst-kix_9ip76qflzlq2-6 {
    list-style-type: none;
  }
  .lst-kix_9ip76qflzlq2-2 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_9ip76qflzlq2-3 > li:before {
    content: "\x25cf   ";
  }
  ul.lst-kix_9ip76qflzlq2-1 {
    list-style-type: none;
  }
  .lst-kix_9ip76qflzlq2-0 > li:before {
    content: "\x25cf   ";
  }
  ul.lst-kix_9ip76qflzlq2-0 {
    list-style-type: none;
  }
  .lst-kix_9ip76qflzlq2-1 > li:before {
    content: "\x25cb   ";
  }
  .lst-kix_f6dll8qypuru-0 > li:before {
    content: "\x25cf   ";
  }
  ul.lst-kix_9ip76qflzlq2-5 {
    list-style-type: none;
  }
  .lst-kix_hkb6qhp9tarx-6 > li {
    counter-increment: lst-ctn-kix_hkb6qhp9tarx-6;
  }
  ul.lst-kix_9ip76qflzlq2-4 {
    list-style-type: none;
  }
  .lst-kix_v5wwndz3vkk4-3 > li:before {
    content: "" counter(lst-ctn-kix_v5wwndz3vkk4-3, decimal) ". ";
  }
  ul.lst-kix_9ip76qflzlq2-3 {
    list-style-type: none;
  }
  ul.lst-kix_9ip76qflzlq2-2 {
    list-style-type: none;
  }
  .lst-kix_v5wwndz3vkk4-2 > li:before {
    content: "" counter(lst-ctn-kix_v5wwndz3vkk4-2, lower-roman) ". ";
  }
  ol.lst-kix_hkb6qhp9tarx-1.start {
    counter-reset: lst-ctn-kix_hkb6qhp9tarx-1 0;
  }
  .lst-kix_f6dll8qypuru-2 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_v5wwndz3vkk4-0 > li:before {
    content: "" counter(lst-ctn-kix_v5wwndz3vkk4-0, decimal) ". ";
  }
  .lst-kix_v5wwndz3vkk4-1 > li:before {
    content: "" counter(lst-ctn-kix_v5wwndz3vkk4-1, lower-latin) ". ";
  }
  .lst-kix_f6dll8qypuru-1 > li:before {
    content: "\x25cb   ";
  }
  ul.lst-kix_f6dll8qypuru-0 {
    list-style-type: none;
  }
  .lst-kix_f6dll8qypuru-6 > li:before {
    content: "\x25cf   ";
  }
  .lst-kix_f6dll8qypuru-8 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_hkb6qhp9tarx-1 > li:before {
    content: "" counter(lst-ctn-kix_hkb6qhp9tarx-1, lower-roman) ". ";
  }
  .lst-kix_hkb6qhp9tarx-3 > li:before {
    content: "" counter(lst-ctn-kix_hkb6qhp9tarx-3, lower-latin) ". ";
  }
  ul.lst-kix_f6dll8qypuru-2 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-7.start {
    counter-reset: lst-ctn-kix_ayreg0ssts0u-7 0;
  }
  ul.lst-kix_f6dll8qypuru-1 {
    list-style-type: none;
  }
  ul.lst-kix_f6dll8qypuru-4 {
    list-style-type: none;
  }
  .lst-kix_f6dll8qypuru-3 > li:before {
    content: "\x25cf   ";
  }
  ul.lst-kix_f6dll8qypuru-3 {
    list-style-type: none;
  }
  .lst-kix_f6dll8qypuru-7 > li:before {
    content: "\x25cb   ";
  }
  .lst-kix_hkb6qhp9tarx-2 > li:before {
    content: "" counter(lst-ctn-kix_hkb6qhp9tarx-2, decimal) ". ";
  }
  ul.lst-kix_f6dll8qypuru-6 {
    list-style-type: none;
  }
  ul.lst-kix_f6dll8qypuru-5 {
    list-style-type: none;
  }
  ul.lst-kix_f6dll8qypuru-8 {
    list-style-type: none;
  }
  .lst-kix_f6dll8qypuru-4 > li:before {
    content: "\x25cb   ";
  }
  ul.lst-kix_f6dll8qypuru-7 {
    list-style-type: none;
  }
  .lst-kix_v5wwndz3vkk4-4 > li:before {
    content: "" counter(lst-ctn-kix_v5wwndz3vkk4-4, lower-latin) ". ";
  }
  ol.lst-kix_v5wwndz3vkk4-0.start {
    counter-reset: lst-ctn-kix_v5wwndz3vkk4-0 0;
  }
  .lst-kix_v5wwndz3vkk4-5 > li:before {
    content: "" counter(lst-ctn-kix_v5wwndz3vkk4-5, lower-roman) ". ";
  }
  .lst-kix_f6dll8qypuru-5 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_hkb6qhp9tarx-0 > li:before {
    content: "" counter(lst-ctn-kix_hkb6qhp9tarx-0, lower-latin) ". ";
  }
  ol.lst-kix_hkb6qhp9tarx-4.start {
    counter-reset: lst-ctn-kix_hkb6qhp9tarx-4 0;
  }
  .lst-kix_v5wwndz3vkk4-6 > li:before {
    content: "" counter(lst-ctn-kix_v5wwndz3vkk4-6, decimal) ". ";
  }
  .lst-kix_v5wwndz3vkk4-7 > li:before {
    content: "" counter(lst-ctn-kix_v5wwndz3vkk4-7, lower-latin) ". ";
  }
  ol.lst-kix_hkb6qhp9tarx-7.start {
    counter-reset: lst-ctn-kix_hkb6qhp9tarx-7 0;
  }
  ul.lst-kix_q72dr9flkute-5 {
    list-style-type: none;
  }
  .lst-kix_hkb6qhp9tarx-4 > li {
    counter-increment: lst-ctn-kix_hkb6qhp9tarx-4;
  }
  .lst-kix_hkb6qhp9tarx-7 > li {
    counter-increment: lst-ctn-kix_hkb6qhp9tarx-7;
  }
  ul.lst-kix_q72dr9flkute-6 {
    list-style-type: none;
  }
  .lst-kix_9ip76qflzlq2-5 > li:before {
    content: "\x25a0   ";
  }
  ul.lst-kix_q72dr9flkute-7 {
    list-style-type: none;
  }
  ul.lst-kix_q72dr9flkute-8 {
    list-style-type: none;
  }
  ol.lst-kix_v5wwndz3vkk4-3.start {
    counter-reset: lst-ctn-kix_v5wwndz3vkk4-3 0;
  }
  ul.lst-kix_q72dr9flkute-1 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-4.start {
    counter-reset: lst-ctn-kix_ayreg0ssts0u-4 0;
  }
  .lst-kix_v5wwndz3vkk4-8 > li:before {
    content: "" counter(lst-ctn-kix_v5wwndz3vkk4-8, lower-roman) ". ";
  }
  ul.lst-kix_q72dr9flkute-2 {
    list-style-type: none;
  }
  .lst-kix_9ip76qflzlq2-6 > li:before {
    content: "\x25cf   ";
  }
  .lst-kix_9ip76qflzlq2-7 > li:before {
    content: "\x25cb   ";
  }
  ul.lst-kix_q72dr9flkute-3 {
    list-style-type: none;
  }
  ul.lst-kix_q72dr9flkute-4 {
    list-style-type: none;
  }
  ol.lst-kix_v5wwndz3vkk4-0 {
    list-style-type: none;
  }
  ol.lst-kix_v5wwndz3vkk4-2 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-8.start {
    counter-reset: lst-ctn-kix_ayreg0ssts0u-8 0;
  }
  ol.lst-kix_v5wwndz3vkk4-1 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-1.start {
    counter-reset: lst-ctn-kix_ayreg0ssts0u-1 0;
  }
  ol.lst-kix_v5wwndz3vkk4-6.start {
    counter-reset: lst-ctn-kix_v5wwndz3vkk4-6 0;
  }
  .lst-kix_q72dr9flkute-0 > li:before {
    content: "\x25cf   ";
  }
  ol.lst-kix_v5wwndz3vkk4-8 {
    list-style-type: none;
  }
  ol.lst-kix_v5wwndz3vkk4-7 {
    list-style-type: none;
  }
  .lst-kix_q72dr9flkute-1 > li:before {
    content: "\x25cb   ";
  }
  ol.lst-kix_v5wwndz3vkk4-4 {
    list-style-type: none;
  }
  ol.lst-kix_v5wwndz3vkk4-3 {
    list-style-type: none;
  }
  ol.lst-kix_v5wwndz3vkk4-6 {
    list-style-type: none;
  }
  .lst-kix_q72dr9flkute-2 > li:before {
    content: "\x25a0   ";
  }
  ol.lst-kix_v5wwndz3vkk4-5 {
    list-style-type: none;
  }
  .lst-kix_hkb6qhp9tarx-3 > li {
    counter-increment: lst-ctn-kix_hkb6qhp9tarx-3;
  }
  .lst-kix_ayreg0ssts0u-3 > li:before {
    content: "" counter(lst-ctn-kix_ayreg0ssts0u-3, decimal) ". ";
  }
  .lst-kix_ayreg0ssts0u-4 > li:before {
    content: "" counter(lst-ctn-kix_ayreg0ssts0u-4, lower-latin) ". ";
  }
  ol.lst-kix_hkb6qhp9tarx-3.start {
    counter-reset: lst-ctn-kix_hkb6qhp9tarx-3 0;
  }
  .lst-kix_ayreg0ssts0u-0 > li:before {
    content: "" counter(lst-ctn-kix_ayreg0ssts0u-0, decimal) ". ";
  }
  .lst-kix_ayreg0ssts0u-1 > li:before {
    content: "" counter(lst-ctn-kix_ayreg0ssts0u-1, lower-latin) ". ";
  }
  .lst-kix_ayreg0ssts0u-2 > li:before {
    content: "" counter(lst-ctn-kix_ayreg0ssts0u-2, lower-roman) ". ";
  }
  .lst-kix_ayreg0ssts0u-4 > li {
    counter-increment: lst-ctn-kix_ayreg0ssts0u-4;
  }
  .lst-kix_v5wwndz3vkk4-6 > li {
    counter-increment: lst-ctn-kix_v5wwndz3vkk4-6;
  }
  ul.lst-kix_31qmn3wc8vet-0 {
    list-style-type: none;
  }
  .lst-kix_ayreg0ssts0u-7 > li {
    counter-increment: lst-ctn-kix_ayreg0ssts0u-7;
  }
  ol.lst-kix_ayreg0ssts0u-8 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-7 {
    list-style-type: none;
  }
  ul.lst-kix_31qmn3wc8vet-2 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-6 {
    list-style-type: none;
  }
  ul.lst-kix_31qmn3wc8vet-1 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-5 {
    list-style-type: none;
  }
  ul.lst-kix_31qmn3wc8vet-4 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-4 {
    list-style-type: none;
  }
  .lst-kix_v5wwndz3vkk4-0 > li {
    counter-increment: lst-ctn-kix_v5wwndz3vkk4-0;
  }
  ul.lst-kix_31qmn3wc8vet-3 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-3 {
    list-style-type: none;
  }
  ul.lst-kix_31qmn3wc8vet-6 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-2 {
    list-style-type: none;
  }
  .lst-kix_v5wwndz3vkk4-3 > li {
    counter-increment: lst-ctn-kix_v5wwndz3vkk4-3;
  }
  ul.lst-kix_31qmn3wc8vet-5 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-1 {
    list-style-type: none;
  }
  ol.lst-kix_v5wwndz3vkk4-5.start {
    counter-reset: lst-ctn-kix_v5wwndz3vkk4-5 0;
  }
  ul.lst-kix_31qmn3wc8vet-8 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-0 {
    list-style-type: none;
  }
  ol.lst-kix_ayreg0ssts0u-2.start {
    counter-reset: lst-ctn-kix_ayreg0ssts0u-2 0;
  }
  ul.lst-kix_31qmn3wc8vet-7 {
    list-style-type: none;
  }
  ol.lst-kix_hkb6qhp9tarx-2.start {
    counter-reset: lst-ctn-kix_hkb6qhp9tarx-2 0;
  }
  .lst-kix_ayreg0ssts0u-1 > li {
    counter-increment: lst-ctn-kix_ayreg0ssts0u-1;
  }
  ol.lst-kix_v5wwndz3vkk4-1.start {
    counter-reset: lst-ctn-kix_v5wwndz3vkk4-1 0;
  }
  ol.lst-kix_hkb6qhp9tarx-5.start {
    counter-reset: lst-ctn-kix_hkb6qhp9tarx-5 0;
  }
  ol.lst-kix_ayreg0ssts0u-3.start {
    counter-reset: lst-ctn-kix_ayreg0ssts0u-3 0;
  }
  .lst-kix_v5wwndz3vkk4-5 > li {
    counter-increment: lst-ctn-kix_v5wwndz3vkk4-5;
  }
  .lst-kix_31qmn3wc8vet-7 > li:before {
    content: "\x25cb   ";
  }
  .lst-kix_31qmn3wc8vet-6 > li:before {
    content: "\x25cf   ";
  }
  ol.lst-kix_ayreg0ssts0u-6.start {
    counter-reset: lst-ctn-kix_ayreg0ssts0u-6 0;
  }
  .lst-kix_ayreg0ssts0u-2 > li {
    counter-increment: lst-ctn-kix_ayreg0ssts0u-2;
  }
  .lst-kix_31qmn3wc8vet-5 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_hkb6qhp9tarx-0 > li {
    counter-increment: lst-ctn-kix_hkb6qhp9tarx-0;
  }
  ol.lst-kix_v5wwndz3vkk4-4.start {
    counter-reset: lst-ctn-kix_v5wwndz3vkk4-4 0;
  }
  ol.lst-kix_hkb6qhp9tarx-8.start {
    counter-reset: lst-ctn-kix_hkb6qhp9tarx-8 0;
  }
  .lst-kix_v5wwndz3vkk4-4 > li {
    counter-increment: lst-ctn-kix_v5wwndz3vkk4-4;
  }
  .lst-kix_ayreg0ssts0u-3 > li {
    counter-increment: lst-ctn-kix_ayreg0ssts0u-3;
  }
  ol.lst-kix_ayreg0ssts0u-0.start {
    counter-reset: lst-ctn-kix_ayreg0ssts0u-0 0;
  }
  .lst-kix_31qmn3wc8vet-8 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_ayreg0ssts0u-7 > li:before {
    content: "" counter(lst-ctn-kix_ayreg0ssts0u-7, lower-latin) ". ";
  }
  .lst-kix_ayreg0ssts0u-5 > li:before {
    content: "" counter(lst-ctn-kix_ayreg0ssts0u-5, lower-roman) ". ";
  }
  .lst-kix_ayreg0ssts0u-6 > li:before {
    content: "" counter(lst-ctn-kix_ayreg0ssts0u-6, decimal) ". ";
  }
  .lst-kix_31qmn3wc8vet-3 > li:before {
    content: "\x25cf   ";
  }
  ol.lst-kix_hkb6qhp9tarx-5 {
    list-style-type: none;
  }
  ol.lst-kix_hkb6qhp9tarx-6 {
    list-style-type: none;
  }
  .lst-kix_31qmn3wc8vet-2 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_31qmn3wc8vet-4 > li:before {
    content: "\x25cb   ";
  }
  ol.lst-kix_hkb6qhp9tarx-7 {
    list-style-type: none;
  }
  ol.lst-kix_hkb6qhp9tarx-8 {
    list-style-type: none;
  }
  .lst-kix_q72dr9flkute-3 > li:before {
    content: "\x25cf   ";
  }
  .lst-kix_q72dr9flkute-5 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_q72dr9flkute-4 > li:before {
    content: "\x25cb   ";
  }
  .lst-kix_q72dr9flkute-8 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_ayreg0ssts0u-8 > li:before {
    content: "" counter(lst-ctn-kix_ayreg0ssts0u-8, lower-roman) ". ";
  }
  .lst-kix_31qmn3wc8vet-0 > li:before {
    content: "\x25cf   ";
  }
  .lst-kix_hkb6qhp9tarx-1 > li {
    counter-increment: lst-ctn-kix_hkb6qhp9tarx-1;
  }
  .lst-kix_v5wwndz3vkk4-2 > li {
    counter-increment: lst-ctn-kix_v5wwndz3vkk4-2;
  }
  ol.lst-kix_hkb6qhp9tarx-0 {
    list-style-type: none;
  }
  .lst-kix_q72dr9flkute-7 > li:before {
    content: "\x25cb   ";
  }
  .lst-kix_31qmn3wc8vet-1 > li:before {
    content: "\x25cb   ";
  }
  ol.lst-kix_hkb6qhp9tarx-1 {
    list-style-type: none;
  }
  ol.lst-kix_hkb6qhp9tarx-2 {
    list-style-type: none;
  }
  ol.lst-kix_hkb6qhp9tarx-3 {
    list-style-type: none;
  }
  ol.lst-kix_hkb6qhp9tarx-4 {
    list-style-type: none;
  }
  .lst-kix_q72dr9flkute-6 > li:before {
    content: "\x25cf   ";
  }
  .lst-kix_ayreg0ssts0u-0 > li {
    counter-increment: lst-ctn-kix_ayreg0ssts0u-0;
  }
  .lst-kix_v5wwndz3vkk4-8 > li {
    counter-increment: lst-ctn-kix_v5wwndz3vkk4-8;
  }
  ol.lst-kix_hkb6qhp9tarx-0.start {
    counter-reset: lst-ctn-kix_hkb6qhp9tarx-0 0;
  }
  .lst-kix_ayreg0ssts0u-5 > li {
    counter-increment: lst-ctn-kix_ayreg0ssts0u-5;
  }
  .lst-kix_v5wwndz3vkk4-1 > li {
    counter-increment: lst-ctn-kix_v5wwndz3vkk4-1;
  }
  ol.lst-kix_ayreg0ssts0u-5.start {
    counter-reset: lst-ctn-kix_ayreg0ssts0u-5 0;
  }
  .lst-kix_hkb6qhp9tarx-5 > li:before {
    content: "" counter(lst-ctn-kix_hkb6qhp9tarx-5, decimal) ". ";
  }
  ol.lst-kix_v5wwndz3vkk4-2.start {
    counter-reset: lst-ctn-kix_v5wwndz3vkk4-2 0;
  }
  .lst-kix_ayreg0ssts0u-6 > li {
    counter-increment: lst-ctn-kix_ayreg0ssts0u-6;
  }
  ol.lst-kix_hkb6qhp9tarx-6.start {
    counter-reset: lst-ctn-kix_hkb6qhp9tarx-6 0;
  }
  .lst-kix_hkb6qhp9tarx-4 > li:before {
    content: "" counter(lst-ctn-kix_hkb6qhp9tarx-4, lower-roman) ". ";
  }
  ol.lst-kix_v5wwndz3vkk4-7.start {
    counter-reset: lst-ctn-kix_v5wwndz3vkk4-7 0;
  }
  .lst-kix_v5wwndz3vkk4-7 > li {
    counter-increment: lst-ctn-kix_v5wwndz3vkk4-7;
  }
  .lst-kix_hkb6qhp9tarx-8 > li {
    counter-increment: lst-ctn-kix_hkb6qhp9tarx-8;
  }
  .lst-kix_hkb6qhp9tarx-2 > li {
    counter-increment: lst-ctn-kix_hkb6qhp9tarx-2;
  }
  .lst-kix_hkb6qhp9tarx-6 > li:before {
    content: "" counter(lst-ctn-kix_hkb6qhp9tarx-6, lower-latin) ". ";
  }
  .lst-kix_hkb6qhp9tarx-7 > li:before {
    content: "" counter(lst-ctn-kix_hkb6qhp9tarx-7, lower-roman) ". ";
  }
  .lst-kix_hkb6qhp9tarx-5 > li {
    counter-increment: lst-ctn-kix_hkb6qhp9tarx-5;
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_hkb6qhp9tarx-8 > li:before {
    content: "" counter(lst-ctn-kix_hkb6qhp9tarx-8, decimal) ". ";
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c1 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: "Arial";
    font-style: normal;
  }
  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal;
  }
  .c11 {
    padding-top: 18pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c3 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal;
  }
  .c13 {
    padding-top: 20pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c7 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 20pt;
    font-family: "Arial";
    font-style: normal;
  }
  .c6 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c12 {
    padding-top: 16pt;
    padding-bottom: 4pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c9 {
    padding-top: 12pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c18 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c17 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal;
  }
  .c8 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
  }
  .c16 {
    font-size: 10pt;
    font-family: "Verdana";
    font-weight: 400;
  }
  .c19 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c14 {
    padding: 0;
    margin: 0;
  }
  .c5 {
    color: inherit;
    text-decoration: inherit;
  }
  .c15 {
    color: #434343;
    font-size: 14pt;
  }
  .c10 {
    height: 11pt;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
  }
  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  `

  return (
    <div>
      <style type="text/css">{styles}</style>
      <h1 class="c13" id="h.sp0x4mj714qt">
        <span class="c7">Privacy Policy </span>
      </h1>
      <p class="c2">
        <span class="c3">
          Sideline Sports a Islandi ehf, org. no. 520601-2790 (&ldquo;Sideline
          Sports&rdquo; or &ldquo;we&rdquo;) provides digital services in the
          form of website, mobile and desktop applications for registering
          members, enabling coaching organization, communication and session
          scheduling and other sport club and coaching functions (&ldquo;the
          Services&rdquo;) to sports clubs and other organizations (&ldquo;the
          Customer&rdquo;).
        </span>
      </p>
      <p class="c2">
        <span>
          <br />
          Welcome, and thank you for your interest in Sideline Sports, our web
          sites at{" "}
        </span>
        <span class="c8">
          <a
            class="c5"
            href="https://www.sidelinesports.com"
          >
            https://sidelinesports.com
          </a>
        </span>
        <span>, </span>
        <span class="c8">
          <a
            class="c5"
            href="https://www.xpsnetwork.com"
          >
            https://xpsnetwork.com
          </a>
        </span>
        <span>&nbsp;(the &ldquo;Site(s)&rdquo;) </span>
        <span class="c0">
          and all related web sites, downloadable software, mobile applications
          (including tablet applications), and other services provided by us and
          on which a link to this Privacy Policy (&ldquo;Policy&rdquo;) is
          displayed, and all other communications with individuals though from
          written or oral means, such as email or phone.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span>
          By the use of the Services, Customers can conduct their operations,
          keep member registers, collect fees, communicate with members,
          schedule training sessions and matches, register and{" "}
        </span>
        <span>analyse</span>
        <span>
          &nbsp;performance results and athlete evaluations, analyze video of
          competition matches and other functions of a sports club.
        </span>
        <span class="c0">
          &nbsp;The Customer&rsquo;s members can be athletes, trainers and
          athletes&rsquo; guardians (&ldquo;Registered Users&rdquo;).
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span class="c0">
          In order to provide Customers the Services, Sideline Sports must
          process personal data on Registered Users. Such processing activities
          are undertaken on behalf of Sideline Sports&rsquo; Customers, where
          the Customers act as data controllers and Sideline Sports as a data
          processor, in the meaning of applicable data protection legislation,
          including the General Data Protection Regulation (&ldquo;GDPR&rdquo;).
          Processing undertaken by Sideline Sports in relation to Registered
          Users is subject to a data processing agreement with the Customers and
          this Policy does not apply to such processing activities.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span class="c0">
          This Policy applies to processing activities where Sideline
          Sport&rsquo;s acts as a data controller, that is in relation to the
          company&rsquo;s processing of personal data on Customer&rsquo;s
          representatives, visitors of the Sites and on job applicants
          (collectively referred to as &ldquo;you&rdquo; in this Policy).
        </span>
      </p>
      <h2 class="c11" id="h.qt942oishqnn">
        <span>1. Personal Data Processed by Sideline Sports</span>
      </h2>
      <p class="c2">
        <span class="c0">
          For the purposes of this Policy, personal data means any information
          relating to an identified or identifiable individual, i.e. information
          that can be traced directly or indirectly to a specific individual.
          Personal data does not include anonymous data or non-personal data
          (i.e., information that cannot be associated with or tracked back to a
          specific individual).
        </span>
      </p>
      <h3 class="c12" id="h.m63buk9qjcja">
        <span class="c15 c17">
          1.1 Representatives of Customers
        </span>
      </h3>
      <p class="c2">
        <span class="c0">
          Sideline Sports processes data on representatives of Customers, such as name, e-mail
          address, phone number, correspondence history etc.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span class="c0">
          The company processes the data to explore the possibilities of
          entering into agreement with the Customer, to provide free trial of
          the Services, to fulfill contractual obligations with the Customer in
          question, to provide support services (incl. via email or phone)
          and/or for marketing purposes (incl. newsletters).
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span class="c0">
          The processing is based on the legitimate interests of Sideline
          Sports.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span class="c0">
          Data on Customers&rsquo; representatives is preserved for 5 years from
          the end of the business relationship with the Customer in question.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <h3 class="c12" id="h.m63buk9qjcja">
        <span class="c15 c17">1.2 Website</span>
      </h3>
      <p class="c2">
        <span class="c0">
          When you visit the Sites we collect IP address, browser version and
          session details in relation to our use of cookies. Cookies are small
          text files placed on the user&#39;s computer or other devices when
          visiting certain web pages. They store small amounts of information
          about the user&#39;s visit. The processing is partly necessary for the
          Sites to work, but in other instances we use the data for analytical
          and marketing purposes subject to your consent.
        </span>
      </p>
      <p class="c2">
        <span class="c0">
          Users can always change settings in their web browser for the
          management of cookies. However, if certain cookie features are
          disabled, the function and experience of Sideline Sports&#39;s
          Services may be impaired. Further information on what cookies we use,
          the basis for the processing and information on how to turn off the
          cookies can be found here:
        </span>
      </p>
      <p class="c9">
        <span class="c8">
          <a
            class="c5"
            href="https://cookies-spravne.cz/cookie-policy?key=n5dM8ap24sbUdHs56dbw&amp;lang=en"
          >
            https://cookies-spravne.cz/cookie-policy?key=n5dM8ap24sbUdHs56dbw&amp;lang=en
          </a>
        </span>
      </p>
      <p class="c9">
        <span>If you contact us via a contact form on the Sites, or via </span>
        <span class="c8">
          <a class="c5" href="mailto:sales@sidelinesports.com">
            sales@sidelinesports.com
          </a>
        </span>
        <span>&nbsp;or </span>
        <span class="c8">
          <a class="c5" href="mailto:info@sidelinesports.com">
            info@sidelinesports.com
          </a>
        </span>
        <span class="c0">
          &nbsp;we also process the personal data you provide, including name,
          e-mail address and the subject of the request. The processing is
          necessary for Sideline Sports to reply to such a request.
        </span>
      </p>
      <h3 class="c12" id="h.m63buk9qjcja">
        <span class="c15 c17">1.3 Job applicants</span>
      </h3>
      <p class="c2">
        <span class="c0">
          When individuals apply for a job at Sideline Sports the company
          processes the applicant&rsquo;s personal data. That includes the
          applicant&rsquo;s name, title, gender, national ID number, address,
          postal code, town/city, country, e-mail address, telephone, education
          and training data, work experience, previous employers, curriculum
          vitae, as well as other information voluntarily submitted by an
          applicant.
        </span>
      </p>
      <p class="c9">
        <span class="c0">
          The company processes the data to be able to select the best candidate
          for the position and for communication with the applicants.
        </span>
      </p>
      <p class="c9">
        <span class="c0">
          The processing is based on the applicant&rsquo;s request to enter into
          a contract with Sideline Sports.
        </span>
      </p>
      <p class="c9">
        <span class="c0">
          The company retains information submitted by job applicants for six
          months, unless a specific consent is requested for a longer retention
          period.
        </span>
      </p>
      <h2 class="c11" id="h.ryajop13f5s">
        <span>2. Data Security</span>
      </h2>
      <p class="c2">
        <span class="c0">
          Sideline Sports endeavors to maintain physical, technical and procedural safeguards which
          are designed to protect personal data from loss and unauthorized
          access, copying, use, modification or disclosure.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span class="c0">
          Sideline Sports has, among other things, implemented strict and
          continuous security routines concerning the processing of personal
          data. Appropriate and reasonable standards for technical data
          protection and so-called built-in data protection are also applied to
          secure and protect personal data, including the following:
        </span>
      </p>
      <ul class="c14 start">
        <li class="c1 li-bullet-0">
          <span class="c0">
            All employees are bound by a confidentiality agreement,
          </span>
        </li>
        <li class="c1 li-bullet-0">
          <span class="c0">
            All employees have undergone security and personal data training,
          </span>
        </li>
        <li class="c1 li-bullet-0">
          <span class="c0">
            All employees, by being placed in appropriate access groups, only
            have access to systems and personal data necessary for them to carry
            out their work tasks,
          </span>
        </li>
        <li class="c1 li-bullet-0">
          <span class="c0">
            Access to personal data is limited to the persons and purposes
            required for Sideline Sports to provide the Services and ensure
            their operation and security,
          </span>
        </li>
        <li class="c1 li-bullet-0">
          <span class="c0">
            Logging and continuous monitoring ensure that no employee misuses
            rights or permissions,
          </span>
        </li>
        <li class="c1 li-bullet-0">
          <span class="c0">
            Only a few key individuals have knowledge of, access to, and the
            ability to influence how the security system is structured,
          </span>
        </li>
        <li class="c1 li-bullet-0">
          <span class="c0">
            Assessment, evaluation, and revision of routines and security are
            continually performed,
          </span>
        </li>
        <li class="c1 li-bullet-0">
          <span class="c0">
            Encryption, firewalls, and other security mechanisms are used to
            minimize the risk of unauthorized access, and
          </span>
        </li>
        <li class="c1 li-bullet-0">
          <span class="c0">
            Storage takes place on highly secure servers with daily data backups
            to avoid the risk of destruction. These are also protected from
            unauthorized access through strict access controls.
          </span>
        </li>
      </ul>
      <p class="c2">
        <span class="c0">
          <br />
          In case of a data breach, the company will inform data subjects and
          the authorities of the occurrence of the breach in accordance with
          applicable law.
        </span>
      </p>
      <h2 class="c11" id="h.a9br8dbgvaum">
        <span>3</span>
        <span>. Your </span>
        <span>Rights</span>
        <span class="c4">&nbsp; </span>
      </h2>
      <p class="c2">
        <span class="c0">
          It is important that personal data in our records is both accurate and
          current.&#8239;You are entitled to request rectification of inaccurate
          data on you. Taking into account the purpose of the processing, you
          also have the right to have incomplete personal data completed.
        </span>
      </p>
      <p class="c2">
        <span class="c0">&nbsp;</span>
      </p>
      <p class="c2">
        <span class="c0">
          You are entitled to request access to the personal data we process on
          you and information on the processing. You may also be entitled to a
          copy of the personal data undergoing processing. Where you have
          provided us with your personal data which we process based on your
          consent or our contract with you, you may have the right to receive
          such data in a machine-readable format and to have the data
          transferred to a third party.
        </span>
      </p>
      <p class="c2">
        <span class="c0">&nbsp;</span>
      </p>
      <p class="c2">
        <span class="c0">
          Under certain circumstances you may have the right to request us to
          erase personal data concerning you with undue delay, such as where the
          personal data is no longer necessary in relation to the purpose for
          which they were collected or otherwise processed or if you withdraw
          your consent and where there is no other legal ground for the
          processing. You may also have the right to restrict further processing
          of your data where certain requirements are fulfilled, such as if the
          processing is unlawful and you prefer the restriction of data
          processing instead of erasure of the data. &nbsp;
        </span>
      </p>
      <p class="c18">
        <span class="c0">
          Please note that your rights relating to your personal data are not
          all absolute. In the event Sideline Sports cannot approve your request
          in relation to your personal data, the company will endeavor to inform
          you of the reasons why, subject to any legal or regulatory
          restrictions.
        </span>
      </p>
      <p class="c18">
        <span class="c0">
          If your request is approved, the necessary action will be taken within
          one month of receipt of the request. That period may be extended by
          two further months where necessary, taking into account the complexity
          and number of requests.
        </span>
      </p>
      <p class="c18">
        <span>
          If you want to use any of your rights referred to in this Policy or if
          you have any questions regarding our processing of your personal data,
          please contact Sideline Sport&rsquo;s Data Protection Officer at{" "}
        </span>
        <span class="c8">
          <a class="c5" href="mailto:dpo@sidelinesports.com">
            dpo@sidelinesports.com
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="c18">
        <span>
          If you are not satisfied with our response, you are entitled to make a
          written submission to the applicable data protection authority,
          including the Icelandic Data Protection Authority (
        </span>
        <span class="c8">
          <a
            class="c5"
            href="https://www.personuvernd.is"
          >
            www.personuvernd.is
          </a>
        </span>
        <span>). &nbsp;</span>
      </p>
      <h2 class="c11" id="h.zazyjn8kealw">
        <span>4. To Whom We Disclose Information</span>
      </h2>
      <p class="c2">
        <span>Sideline Sports w</span>
        <span class="c0">
          orks with third party service providers who provide website,
          application development, hosting, maintenance, and other services for
          the company. These third parties may have access to, or process
          personal data as part of providing those services for the company.
          Sideline Sports limits the information provided to these service
          providers to that which is reasonably necessary for them to perform
          their functions, and the company&rsquo;s contracts with them require
          them to maintain the confidentiality of such information.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span class="c0">
          Sideline Sports may also disclose personal data or other information
          if required to do so by law, in response to a facially valid court
          order, judicial or other government subpoena or warrant, or to
          otherwise cooperate with law enforcement or other governmental
          agencies.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span class="c0">
          The company also reserves the right to disclose personal data or other
          information that we believe, in good faith, is appropriate or
          necessary to (i) take precautions against liability, (ii) protect
          ourselves or others from fraudulent, abusive, or unlawful uses or
          activity, (iii) investigate and defend ourselves against any
          third-party claims or allegations, (iv) protect the security or
          integrity of the Services and any facilities or equipment used to make
          the Services available, or (v) protect our property or other legal
          rights, enforce our contracts, or protect the rights, property, or
          safety of others.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span class="c0">
          Information on you may furthermore be disclosed and otherwise
          transferred to an acquirer, successor or assignee as part of any
          merger, acquisition, debt financing, sale of assets, or similar
          transaction, as well as in the event of an insolvency, bankruptcy, or
          receivership in which information is transferred to one or more third
          parties as one of our business assets.
        </span>
      </p>
      <h2 class="c11" id="h.9pdd0behsyef">
        <span>5. Your California Privacy Rights</span>
      </h2>
      <p class="c2">
        <span class="c0">
          We will not share any Personal Data with third-parties for their
          direct marketing purposes to the extent prohibited by California law.
          If our practices change, we will do so in accordance with applicable
          laws and will notify you in advance.
        </span>
      </p>
      <h2 class="c11" id="h.wi7a9n2svg1k">
        <span>6. Revisions of this Policy</span>
      </h2>
      <p class="c2">
        <span>Sideline Sports </span>
        <span>
          may from time to time make changes to this Policy to reflect changes
          in our legal or regulatory obligations or in the manner in which we
          deal with your personal data.&#8239;We will communicate any revised
          version of this Policy.&#8239; Any changes to this Privacy Policy will
          be effective from the time they are communicated.
        </span>
        <span class="c16">&nbsp;</span>
      </p>
      <h2 class="c11" id="h.fzx68gpo7b7">
        <span>7. How to Contact Us</span>
      </h2>
      <p class="c2">
        <span>
          Please contact us with any questions or comments about this Policy,
          your Personal Data, our use and disclosure practices, or your consent
          choices by email at{" "}
        </span>
        <span class="c8">
          <a class="c5" href="mailto:support@sidelinesports.com">
            support@sidelinesports.com
          </a>
        </span>
        <span>
          . If you have any concerns or complaints about this Policy or your
          Personal Data, you may contact Sideline Sports&rsquo;s Data Protection
          Officer by email at{" "}
        </span>
        <span class="c8">
          <a class="c5" href="mailto:dpo@sidelinesports.com">
            dpo@sidelinesports.com
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2">
        <span class="c0">
          For the personal data that the Customer processes about Registered
          Users, where Sideline Sports acts as a data processor, we ask you to
          contact the Customer with your questions and concerns.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2" style={{ marginTop: 30 }}>
        <span class="c0">Last updated: February 21, 2024</span>
      </p>
      <p class="c6">
        <span class="c0"></span>
      </p>
    </div>
  )
}
